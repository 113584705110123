<template>
  <div class="lg:text-xl about-us home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeader />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner pt-12 text-gray-800 bg-center">
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">About Us</h2>
        <p class="lg:text-base text-sm">Home > <span class="text-sitePurple font-bold">About Us</span></p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
            <div class="w-full px-4 overflow-hidden">
              <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold">Clinic Information</h4>
              <img class="w-full mb-8" src="images/image-about-01.jpg">
              <p class="mb-4">
                Headed by Medical Director Dr Marco Faria Correa, Dr Marco Plastic Surgery is an international clinic
                with roots in both Singapore and Brazil. Located at Mount Elizabeth Novena, the headquarters in
                Singapore is tastefully decorated and designed to be warm, inviting and comfortable for all local and
                expatriate patients.
              </p>
              <p class="mb-4">
                Dr Marco holds hospital privileges in over 23 hospitals around the world, and is experienced in
                performing makeovers and other impactful procedures in some of Singapore’s finest hospitals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl pt-12 pb-8 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <img class="mb-4" src="images/image-doctor.jpg">
          </div>
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <div class="lg:pl-8">
              <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold">Doctor Information</h4>
              <p class="mb-4">
                Plastic Surgeon Dr Marco Faria Correa is an experienced surgeon that has spent the last 35 years working
                with patients of all ethnic groups and nationalities, deeply understanding the different body types and
                needs of international patients.
              </p>
              <p class="mb-4">
                With a firm emphasis on patient safety and experience, Dr Marco only performs treatments in
                fully-equipped hospitals that are up to his standards.
              </p>
              <p class="mb-8">
                Dr Marco graduated from the School of Medicine at the Federal University of Rio Grande do Sul in Brazil
                in 1978 and achieved certification and professional recognition in Plastic Surgery in 1984.
              </p>
            </div>
          </div>
          <div class="w-full px-4 overflow-hidden">
            <h4 class="lg:text-2xl lg:mt-8 lg:mb-6 text-sitePurple mb-4 text-xl font-bold">His Singapore Story</h4>
            <p class="mb-4">
              After years of practice and honing his skills, Dr Marco first came to Singapore in 1996 for conducting
              surgical demonstrations and workshops to educate doctors on his technique of endoscopic abdominoplasty.
              Later in 2000, he was invited by the Singapore Association of Plastic Surgery to present his techniques in
              “Refinements in Liposculpture with a patient in Standing Position” as well as providing updates on
              “Endoscopic Plastic Surgery”.
            </p>
            <p class="mb-4">
              When the Ministry of Health rolled out the “Hunting for Talents” programme, Dr Marco was invited to work
              in Singapore to attract more international patients and to contribute to turning Singapore into a Plastic
              & Cosmetic Surgery Hub.
            </p>
            <p class="mb-4">
              Thereafter, Dr Marco obtained a conditional Certificate of Medical Registration from the Singapore Medical
              Council in 2000 and in March 2002, he was awarded the Certificate of Specialist Accreditation by the
              Ministry of Health, Singapore. A month later, he received the Certificate of Specialist Registration in
              Plastic Surgery. In 2005, Dr Marco obtained his complete registration with the Singapore Medical Council
              and gained recognition as a fully-fledged member of the medical community.
            </p>
            <p class="mb-8">
              Dr Marco believes in putting patients first and has attained surgical permissions at hospitals around
              Singapore to provide encompassing care and convenience for all patients. With hospital privileges in over
              23 hospitals around the world, Dr Marco is experienced in performing makeovers and other impactful
              procedures in some of Singapore’s finest hospitals.
            </p>
          </div>
        </div>
        <div class="lg:mt-8 lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-01.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-02.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-03.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-04.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-05.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-06.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-07.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-08.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-09.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-15.jpg">
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="md:pb-12 lg:pb-16 max-w-screen-xl pb-8 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <h4 class="lg:text-2xl lg:mt-8 lg:mb-6 text-sitePurple mb-4 text-xl font-bold">Awards & Legacy</h4>
            <p class="mb-4">
              Over the years, he has won several international awards for his work in increasing the safety of
              Endoscopic Abdominoplasty and Breast Surgery. As such, he is frequently invited to conduct surgical
              demonstrations and to impart his knowledge on these improved techniques. In order to share his knowledge
              to future generations of surgeons, Dr Marco has also published many journals and book chapters relating to
              the field of plastic & cosmetic surgery.
            </p>
            <p class="mb-4">
              Dr Marco has garnered numerous awards for his contributions to the field, some of them include:
            </p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="mb-1">1996: “Walter Scott Brown Award” Best Videotape presented at the 1995 Annual Meeting of
                the American Society for Aesthetic Plastic Surgery</li>
              <li class="mb-1">1996: “Award Dr. J.J.Legrand” “Prêmio Dr. J. J. Legrand” Conferred by the Brazilian
                Society of Aesthetic Medicine 1rst Panamerican Congress of Aesthetic Medicine</li>
              <li class="mb-1">1998: “Masumi Inaba, MD’s Crowned Prize” Conferred by the Japan Society of Cosmetic
                Surgeons The 74th Congress of Japan Society of Aesthetic Surgery Tokyo, Japan</li>
              <li class="mb-1">2000: “A Prize for Excellence and Devotion to Cosmetic Surgery” Conferred by the
                International Society of Aesthetic Surgery The 3rd World Congress of ISAS The 78th Congress of JSAS The
                2nd Congress of JSLS Tokyo, Japan</li>
              <li class="mb-1">2001: “Leader of Cosmetic Surgery in Millennium” Conferred by the Federation of
                Restorative & Cosmetic Surgery of India The World Congress on Aesthetic and Restorative Surgery VIII
                International Multifaculty Medical Conference Mumbai, India</li>
              <li class="mb-1">2009: “MOST OUTSTANDING INTERNATIONAL LEADER IN LIPOSUCTION AND COSMETIC SURGERY” Granted
                by the scientific committee of the 1st World Congress on “Advances in Body Contouring” of the
                Philippines Society of Liposuction Surgery , Inc in cooperation with the Philippine Society for Cosmetic
                Surgery Philippine Academy of Dermatologic Surgery Foundation, Inc .and Philippine Academy of Aesthetic
                Surgery</li>
              <li>2016: Award of Recognition for Contribution to Plastic Surgery bringing Mini Abdominoplasty technique
                to the next level of a keyhole minimally surgery by introducing the use of endoscopic methods and
                robotos for rectus plication “ Robotic Abdominoplasty” by Philippine Society of Liposuction Surgery Inc,
                Philippine Academy of Aesthetic Surgery, Philippine Society of Dermatologic Surgery Foundation Inc, Pan
                Pacific Aesthetic Institute Foundation Inc, Philippine Society for Cosmetic Surgery Inc and Asiana
                Pacific Academy of Cosmetic Surgery Inc.</li>
            </ul>
            <p class="mb-8">
              Today, Dr Marco is always on the lookout for safer and more innovative methods that can enhance the
              results while keeping patient care first.
            </p>
            <p class="mb-8">
              View Dr Marco’s CV –  <a href="images/CV-updated-October-2017.pdf" target="_blank"
                class="text-sitePurple font-bold">CV updated October 2017</a>
            </p>
            <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold">Professional Memberships</h4>
            <p class="mb-4">
              Being a well-respected member of the plastic surgery community, Dr Marco is a member of the following
              Professional Societies :
            </p>
            <p class="mb-2 text-lg font-bold">International:</p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="mb-1">International Society of Aesthetic Plastic Surgery/ ISAPS</li>
              <li class="mb-1">American Society of Plastic Surgeons ASPS</li>
              <li class="mb-1">Asia Pacific Hernia Society</li>
            </ul>
            <p class="mb-2 text-lg font-bold">Brazil:</p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="mb-1">Founding Member of Chapter of Endoscopic Plastic Surgery of the Brazilian Society of
                Plastic Surgery</li>
              <li class="mb-1">Founder & Member of Chapter of Rhinology of the Brazilian Society of Plastic Surgery</li>
              <li class="mb-1">Brazilian Society of Plastic Surgery</li>
              <li class="mb-1">Brazilian Society of Reconstructive Microsurgery</li>
              <li class="mb-1">Member of Brazilian College of Surgeon Singapore</li>
            </ul>
            <p class="mb-2 text-lg font-bold">Singapore:</p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="mb-1">Singapore Association of Plastic Surgeons / SAPS</li>
              <li class="mb-1">Singapore Society for Cosmetic (Aesthetic) Surgeons</li>
            </ul>
          </div>
        </div>
        <div class="lg:mt-8 lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-10.jpg">
          </div>
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-11.jpg">
          </div>
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-12.jpg">
          </div>
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-13.jpg">
          </div>
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-14.jpg">
          </div>
        </div>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <SiteForm />
    <SiteFooter />
  </div>
</template>

<script>
  import SiteHeader from '@/components/SiteHeader.vue'
  import SiteForm from '@/components/SiteForm.vue'
  import SiteFooter from '@/components/SiteFooter.vue'
  export default {
    components: {
      SiteHeader,
      SiteForm,
      SiteFooter
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
</style>